import React from "react";
import { Link, graphql } from "gatsby";

import Navigation from "../components/navigation";

export default ({ data }) => (
    <Navigation>
        <div>
            <title>David Weik - Blog</title>
            <div>
                <h1 id="main-title" style={{ textAlign: "center" }}>
                    Blog
                </h1>
                <p style={{ margin: "0rem 1rem" }}>
                    <strong>
                        I have my own Podcast - it is in German and you can find
                        it on{" "}
                        <a href="https://itunes.apple.com/us/podcast/schweigefuchs/id1381230348">
                            iTunes
                        </a>
                        ,{" "}
                        <a href="https://open.spotify.com/show/1qKJ5FoIZX3Jr8RC3fcFzK">
                            Spotify
                        </a>{" "}
                        and{" "}
                        <a href="https://anchor.fm/schweigefuchs">
                            many more Platforms
                        </a>
                        .
                    </strong>
                </p>
                {data.allMarkdownRemark.edges.map(({ node }) => (
                    <div key={node.id}>
                        <Link
                            to={node.fields.slug}
                            style={{ textDecoration: "none", color: "black" }}>
                            <h2
                                style={{
                                    textAlign: "center",
                                    padding: "1rem",
                                    marginBottom: "0.2rem"
                                }}>
                                {node.frontmatter.title}{" "}
                            </h2>
                            <p style={{ margin: "0rem 1rem 1rem 1rem" }}>
                                {node.excerpt}
                            </p>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    </Navigation>
);

export const query = graphql`
    query {
        allMarkdownRemark {
            totalCount
            edges {
                node {
                    id
                    frontmatter {
                        title
                        date
                    }
                    fields {
                        slug
                    }
                    excerpt
                }
            }
        }
    }
`;
